<template>
    <div class="p-fluid mb-3">       
        <div class="grid">
            <div class="lg:col-5 md:col-12 sm:col-12 align-items-center justify-content-center">
                <h5>Guide</h5>
                <ol>
                    <li>
                        <p class="line-height-3 m-0">Please download the template first</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Make sure the distributor has been filled before upload</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Make sure the uploaded file is in Excel or CSV format</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Ensure that the file size is not larger than 2MB</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Ensure that all columns are filled correctly</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Ensure that the cp date column is in the format of Y-m-d, for example: 2023-05-17</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">To view the list of USERNAME, please download it below</p> 
                        <Button :loading="loadingDownloadDeliveryMan" label="Download Delivery Man" icon="pi pi-download" class="p-button-sm p-button-secondary my-1" @click="downloadDeliveryMan('xlsx')" />
                    </li>
                </ol>
                <div class="line-height-3 m-0" style="color:red;">Note: please follow the step-by-step instructions carefully to ensure that the data is uploaded successfully</div>
                <h5>Download Template</h5>
                <div class="p-fluid">
                    <div class="formgrid grid">
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div class="field mt-2 ml-2">
                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplate('xlsx')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="lg:col-1">
                <Divider layout="vertical">
                    <!-- <b>OR</b> -->
                </Divider>
            </div>

            <div class="sm:col-12 md:col-12 lg:col-6">
                <BlockUI :blocked="blockPanel">
                    <form>
                        <div class="p-fluid">
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6">
                                    <div v-if="user?.masterDistributor?.length == 1">
                                        <b>DISTRIBUTOR : {{user?.masterDistributor[0].db_label}}</b>
                                    </div>
                                    <div v-else>
                                        <label for="db_id">Distributor</label>
                                        <Dropdown id="db_id" v-model="forms.db_id" :loading="loadingDropdownDistributor" :options="dataDropdownDistributor" :class="{ 'p-invalid': errors.db_id }" optionLabel="db_label" optionValue="db_id" placeholder="Choose Distributor" :filter="true" :showClear="true" @filter="searchDropdownDistributor($event ,'add')"/>
                                        <small class="p-invalid" style="color: red" v-if="errors.db_id" >{{ errors.db_id[0] }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FileUpload ref="fileUpload" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Choose" uploadLabel="Upload" cancelLabel="Cancel" :customUpload="true" @uploader="onUpload" :fileLimit="1" :maxFileSize="2000000">
                        <template #empty>
                            <p>Drag and drop files to here to upload.</p>
                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                        </template>
                        </FileUpload>
                    </form>
                </BlockUI>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    data() {
        return {
            // loading
            loadingDownloadTemplate: false,
            loadingDownloadDeliveryMan: false,
            loadingDropdownDistributor: false,

            // dataDropdown
            dataDropdownDistributor: null,

            // upload
            blockPanel: false,
            file: null,
            forms: {
                db_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
        this.searchDropdownDistributor('');
        setTimeout(() => {
            this.forms.db_id = this.user?.masterDistributor?.length == 1 ? this.user?.masterDistributor[0].db_id : null;
        }, 250);

    },
    computed: {
        ...mapGetters(['errors']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.ddistributor.filterValue = valueEdit;
                }
                
                if(purpose == "add"){
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/userweb-distributor',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownDistributor = res.data.data;
                        this.loadingDropdownDistributor = false;
                    }else if(purpose == null){
                        this.dataDropdownDistributor = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // DOWNLOADTEMPLATE
        downloadTemplate(ext){
            this.loadingDownloadTemplate= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/delivery-route/template-upload',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Import Call Plan Template.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();

                this.loadingDownloadTemplate= false;

            })
            .catch((err) => {
                console.log(err);

                this.loadingDownloadTemplate= false;
            });
        },
        downloadDeliveryMan(ext){
            this.loadingDownloadDeliveryMan= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/delivery-route/data-deliveryman',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Delivery Man.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();

                this.loadingDownloadDeliveryMan= false;

            })
            .catch((err) => {
                console.log(err);

                this.loadingDownloadDeliveryMan= false;
            });
        },
        //UPLOAD
        onUpload(event) {

            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('file', this.file);
            data.append('db_id', this.forms.db_id);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/delivery-route/upload',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Successfully Import', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;

                this.clearForms();

                this.$emit('submit');
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Import', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
            });
        },
        clearForms() {
            this.file = null;
            this.forms.db_id = this.user?.masterDistributor?.length == 1 ? this.user?.masterDistributor[0].db_id : null;
        },
    }
}
</script>